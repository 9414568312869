import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import logo from './img/logo.png'
import logoWhite from './img/logo_w.png'
import logoWhats from './img/logo_whats.png'
import us from './img/us.png'
import services01 from './img/services_01.png'
import services02 from './img/services_02.png'
import services03 from './img/services_03.png'
import health from './img/health.png'
import healthw from './img/health_w.png'
import stethoscope from './img/stethoscope.png'

import boing from './img/boing.png'
import ssa from './img/ssa.png'
import isssste from './img/isssste.png'
import repse from './img/repsew.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BandaidFill, BuildingFill, CapsulePill, Clipboard2PulseFill, HeartPulseFill, HospitalFill, PersonBadgeFill, TelephoneFill, TruckFrontFill, Virus, Whatsapp, Instagram, Facebook, Tiktok, Linkedin, Laptop, LaptopFill } from 'react-bootstrap-icons'

const API_PATH = "https://digital-eureka.com/mailer.php";
const SITE_KEY = "6Le7o4UhAAAAAMtKSidIoVlcrI9n29WAI5ukwSfM";

function App() {
  const [showMessage, setShowMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [textBtn, setTextBtn] = useState('ENVIAR')
  const [show, setShow] = useState(false)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const refHome = useRef(null)
  const refUs = useRef(null)
  const refServices = useRef(null)
  const refRules = useRef(null)
  const refContact = useRef(null)

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        //handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    setTextBtn("Enviando...")
    setIsActive(true)

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setTextBtn("ENVIAR")
        setIsActive(false)

        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setTextBtn("ENVIAR")
        setIsActive(false)

        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
      }

    }).catch(function(err) {
      setTextBtn("ENVIAR")
      setIsActive(false) 

      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  return (
    <div className="App">

      <FloatingWhatsApp 
        phoneNumber="5215653710018"
        accountName="InvestigArt"
        className="wa-style"
        statusMessage="En linea"
        avatar={logoWhats}
        chatMessage="Hola, ¡Bienvenido a InvestigArt! Nos alegra que nos escribas. ¿Cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className="custom-bar-contact">
        <Container>
          <Row>
            <Col md={ 12 }>
              <div>
                <span onClick={ () => window.open('https://wa.me/5215653710018') }> <Whatsapp className='nav-icon'/> 56 5371 0018 </span> &nbsp;&nbsp; 
                <span onClick={ () => window.open('https://wa.me/5215513404613') }> <TelephoneFill className='nav-icon'/> 55 5555 5555 </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <Navbar id="rensa-navbar" collapseOnSelect expand="lg" variant="light" className="custom-bar" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" id="brand_image" src={ logo } width="auto" height="60" className="d-inline-block align-top brand-image-start" onClick={() => handleNavClick("refHome")}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav onSelect={handleNavClick} className='align-items-center'>
              <Nav.Link eventKey="refHome">
                <div className="nav-link-custom">Inicio</div>
              </Nav.Link>
              <Nav.Link eventKey="refUs">
                <div className="nav-link-custom">¿Quiénes somos?</div>
              </Nav.Link>
              <Nav.Link eventKey="refServices">
                <div className="nav-link-custom">Servicios</div>
              </Nav.Link>
              <Nav.Link eventKey="refRules">
                <div className="nav-link-custom">Normativa</div>
              </Nav.Link>
              <Nav.Link eventKey="refContact">
                <div className="nav-link-custom"><Button variant="outline-info" style={{ fontSize: '15px' }} onClick={ () => handleNavClick('refContact') }> ¡Contáctanos! </Button></div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className='space-bar'/>
      <div ref={ refHome } className='main-home'>  
        <Container>
          <Row className='align-items-center'>
            <Col md={ 5 }>
              <div className='main-title'>
                El <span className='main-title-bold'>ARTE</span> de <span className='main-title-bold'>INVESTIGAR</span> la Salud en el Trabajo
              </div>
              <div className='main-subtitle'>Somos la primera empresa en México que cuenta con un modelo matemático capaz de predecir la evolución del estado de salud de sus empleados.</div>
              <br/>
              <div align="left"> <Button variant="outline-info" onClick={ () => window.open('https://wa.me/5215653710018') }> ¡Chatea con nosotros! </Button> </div>
            </Col>
            <Col md={ 7 } className='main-image'>
            </Col>
          </Row>
        </Container>
      </div>

      <div ref={ refUs } className='section'>
        <Container>
          <Row className='align-items-center'>
            <Col md={ 6 } className='us-container'>
              <img src={ us } className='img-70' />
            </Col>
            <Col md={ 6 }>
              <div className='section-title'> ¿Quiénes Somos? </div>
              <div className='section-desc'> Somos una red transdisciplinaria de especialistas en Salud Ocupacional que puede resolver cualquier necesidad en su empresa, ya que contamos con 20 años de experiencia en el campo.</div>
            </Col>
          </Row>
        </Container>

        <div align='center'>
          <div className='home-data'>
            <Container>
              <Row>
                <Col md={ 4 } xs={ 6 } align="center">
                  <div className="title-number">+10</div>
                  <div className="title-desc">Servicios Especializados</div>
                </Col>
                <Col md={ 4 } xs={ 6 } align="center">
                  <div className="title-number">+100</div>
                  <div className="title-desc">Consultas a la semana</div>
                </Col>
                <Col md={ 4 } xs={ 12 } align="center">
                  <div className="title-number">+50</div>
                  <div className="title-desc">Empresas Beneficiadas</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div ref={ refServices } className='section main-home'>
        <Container>
          <Row>
            <Col md={ 12 }>
              <div className='section-title' style={{ textAlign: 'center' }}> Nuestros Servicios </div>
              {/* <div className='section-desc' style={{ textAlign: 'center' }}> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div> */}
            </Col>
          </Row>
          <br/>
          <Row className='align-items-center'>
            <Col md={ 6 }>
              <div> <img src={ services01 } className='img-80' /> </div>
            </Col>
            <Col md={ 6 }>
              <Container>
                <Row>
                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <CapsulePill className='service-icon'/> </div>
                      <div className='service-title'> Realización de exámenes médicos de ingreso y periódicos </div>
                      <div className='service-desc'> Los realizamos acorde a un estudio previo que implementaremos a su empresa y si no los tiene enviaremos a personal altamente calificado y el equipo necesario para su realización siguiendo los lineamientos de las instituciones de salud en el país o pueden ser diseñadas específicamente para su empresa con la periodicidad que requiera. </div>
                    </div>
                  </Col>

                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <Clipboard2PulseFill className='service-icon'/> </div>
                      <div className='service-title'> Realización de espirometrías y audiometrías </div>
                      <div className='service-desc'> Contamos con los equipos para tal fin. Si ya están realizadas las someteremos a análisis para sugerir medidas de prevención y  control. </div>
                    </div>
                  </Col>

                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <HeartPulseFill className='service-icon'/> </div>
                      <div className='service-title'> Vigilancia epidemiológica en los centros de trabajo </div>
                      <div className='service-desc'> Abarcamos desde historias clínicas, hasta perfiles completos de trabajadores. </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <br/><br/><br/>
        <Container>
          <Row className='align-items-center'>
            <Col md={ 5 }>
              <Container>
                <Row>
                  <Col md={ 12 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <PersonBadgeFill className='service-icon'/> </div>
                      <div className='service-title'> Médico en tu empresa </div>
                      <div className='service-desc'> Tenemos personal altamente capacitado que puede acudir por horas, o jornada completa. Diseñamos un plan perfecto para su empresa. </div>
                    </div>
                  </Col>

                  <Col md={ 12 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <BuildingFill className='service-icon'/> </div>
                      <div className='service-title'> Enfermera en tu empresa </div>
                      <div className='service-desc'> Enviamos profesionales ya sea por campaña o jornada completa. </div>
                    </div>
                  </Col>

                  <Col md={ 12 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <Virus className='service-icon'/> </div>
                      <div className='service-title'> Periciales médicas especializadas </div>
                      <div className='service-desc'> En cualquier estado de la republica. Le asesoramos de manera gratuita respecto de la calificación de la patología. </div>
                    </div>
                  </Col>

                  <Col md={ 12 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <LaptopFill className='service-icon'/> </div>
                      <div className='service-title'> Análisis ergonómico de los puestos de trabajo </div>
                      <div className='service-desc'> La ergonomía aplicada al servicio de su empresa. Somos expertos en el análisis metodológico de los cuatro grandes grupos de riesgos ergonómicos. </div>
                    </div>
                  </Col>

                </Row>
              </Container>
            </Col>
            <Col md={ 7 }>
              <div> <img src={ services02 } className='img-80' /> </div>
            </Col>
          </Row>
        </Container>

        <br/><br/><br/>
        <Container>
          <Row className='align-items-center'>
            <Col md={ 6 }>
              <Container>
                <Row>
                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <BandaidFill className='service-icon'/> </div>
                      <div className='service-title'> Diseño de Diagnóstico Situacional de Salud </div>
                      <div className='service-desc'> Con su realización, se da cumplimiento a la normativa más importante en materia del trabajo. </div>
                    </div>
                  </Col>

                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <Clipboard2PulseFill className='service-icon'/> </div>
                      <div className='service-title'> Diseño de Programas de Prevención y Control de los Efectos de los Riesgos </div>
                      <div className='service-desc'> Podemos abordar un solo agente contaminante, o aplicarle integro al Diagnóstico Situacional de Salud. </div>
                    </div>
                  </Col>

                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <TruckFrontFill className='service-icon'/> </div>
                      <div className='service-title'> Transportistas </div>
                      <div className='service-desc'> Antidopping · Certificados de salud</div>
                    </div>
                  </Col>

                  <Col md={ 6 } className='service-col'>
                    <div>
                      <div className='service-icon-container'> <HospitalFill className='service-icon'/> </div>
                      <div className='service-title'> Wellness </div>
                      <div className='service-desc'> Salud Mental · Nutrición · Rehabilitación </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={ 6 }>
              <div> <img src={ services03 } className='img-80' /> </div>
            </Col>
          </Row>
        </Container>


      </div>

      <div ref={ refRules } className='section dots-background'>
        <Container>
          <Row>
            <Col className='section-title' style={{ textAlign: 'center' }}> Implementación de la Normativa en Trabajo y Salud </Col>
          </Row>
          <br/><br/>
          <Row className='align-items-center'>
            <Col>
              <div className='section-desc' style={{ textAlign: 'center' }}> 
                · Implementación de la <b>NOM-030-STPS-2009</b> <br/>
                · Implementación de la <b>NOM-035-STPS-2018</b> <br/>
                · Implementación de la <b>NOM-004-SSA3-2012</b> <br/>
                · Implementación de la <b>NOM-017-SSA2-2012</b> <br/>
                · Asesoría en materia de la <b>NOM-037-STPS-2023</b> <br/>
              </div>
              <br/><br/>
              <div align='center'>
                <div className='important-desc'> 
                  <div className='service-title' style={{ color: '#fff', textAlign: 'center', paddingTop: 0 }}> IMPORTANTE </div><br/>
                  <div>Si tu empresa ya fue inspeccionada, le ayudamos a presentar probatorios del cumplimiento de la normativa. </div>
                  <div>Si cuenta Con programas de prevención y control de los efectos de los riesgos los revisamos y les asesoramos de manera gratuita para encontrar áreas de oportunidad. </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <br/><br/>
        <Container>
          <Row>
            <Col md={ 4 } />
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container extra-container-principal'>
                <div> <img src={ healthw } className='extra-icon' /> </div>
                <div className='extra-title' style={{ color: '#fff' }}>
                  Contamos con una plataforma de gestión de riesgos de trabajo propia, que se adaptará a su empresa
                </div>
                {/* <div className='extra-desc'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div> */}
              </div>
            </Col>
            <Col md={ 4 } />
          </Row>
          <Row>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Integración y capacitación de la comisión de seguridad e higiene
                </div>
              </div>
            </Col>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Seguimiento Covid-19
                </div>
              </div>
            </Col>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Asesoría para la gestión del teletrabajo
                </div>
              </div>
            </Col>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Regreso a oficinas en trabajo presencial
                </div>
              </div>
            </Col>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Cumplimos con la NOM-004-SSA3-2012
                </div>
              </div>
            </Col>
            <Col md={ 4 } align="center" className='service-col col-md-4-custom'>
              <div className='extra-container'>
                <div> <img src={ health } className='extra-icon' /> </div>
                <div className='extra-title'>
                  Somos capaces de diseñar estrategias para una pandemia
                </div>
              </div>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <div align='center'>
                <div className='home-data' style={{ backgroundColor: '#ffd5d5' }}>
                  <Container>
                    <Row>
                      <Col md={ 12 } xs={ 12 } align="center">
                        <div className="title-number" style={{ color: '#eb3f3f' }}>Próximamente</div>
                        <div className="title-desc" style={{ color: '#000', fontWeight: 'bolder', fontSize: '19px' }}>DIPLOMADO EN SALUD OCUPACIONAL SEGURIDAD E HIGIENE</div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='section dots-background'>
        <Container>
          <Row>
            <Col className='section-title' style={{ textAlign: 'center' }}> Algunos de nuestros Clientes </Col>
          </Row>
          <br/><br/>
          <Row>
            <Col md={ 4 }>
              <div>
                <img src={ isssste } className='client-logo' />
              </div>
            </Col>
            <Col md={ 4 }>
              <div>
                <img src={ boing } className='client-logo' />
              </div>
            </Col>
            <Col md={ 4 }>
              <div>
                <img src={ ssa } className='client-logo' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div ref={ refContact } className='section section-color-gray'>
        <Container>
          <Row>
            <Col style={{ textAlign: 'center' }}> 
              <div className='section-title' style={{ textAlign: 'center' }}>¡Contáctenos! </div><br/>
              <div>
                <div>Contamos con paquetes de servicios exactamente pensados en la necesidad de las empresas mexicanas. </div>
                <div className='main-title-bold'>Nos ajustamos a sus necesidades y presupuesto.</div><br/>
                <div>Déjenos sus datos y uno de nuestros asesores se comunicará con usted a la brevedad.</div>
                <br/>
              </div>
            </Col>
          </Row>
          <br/><br/>
          <Row>
            <Col md={ 6 }>
              <div> <img src={ stethoscope } className='img-100' /> </div>
            </Col>
            <Col md={ 6 } className="contact-container">
              <Form id="contactForm" className="form-style" conSubmit={handleOnClick}>

                <FloatingLabel label="Nombre completo" className="mb-3">
                  <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } placeholder="-" />
                </FloatingLabel>

                <Form.Group className="mb-3">
                  <FloatingLabel label="Correo electrónico">
                    <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } placeholder="-" />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                  <FloatingLabel label="Teléfono">
                    <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } placeholder="-" />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3">
                  <FloatingLabel label="Comentario">
                    <Form.Control required name="comments" as="textarea" value={comments} rows="3" className="textarea-style"  onChange={ (e) => setComments(e.target.value) } placeholder="-" />
                  </FloatingLabel>
                </Form.Group>

                <br/>
                <div align="right">
                  <Button disabled={isActive} variant="outline-info" type="submit">
                    <Spinner
                      className={isActive ? '' : 'hide'}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    { textBtn }
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='footer'>
        <div>
          <Container>
            <Row className='align-items-center' align="center">
            <Col md={ 4}>
              </Col>
              <Col md={ 4} onClick={ () => handleNavClick('refHome') } style={{ cursor: 'pointer' }}>
                <div> <img src={logoWhite} width={110 }/> </div>
              </Col>
              <Col md={ 4}>
                <div> <img src={repse} width={110}/> </div>
              </Col>
            </Row>
            <br/>
            <Row className='align-items-center' align="center">
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'><a href='mailto:contacto@investigart.com.mx' target='_blank' style={{ color: '#fff' }} > contacto@investigart.com.mx </a></Col>
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'>
                <div className='footer-text-desc' style={{ cursor: 'pointer' }} onClick={ () => setShow(true) }>
                  <div>Aviso de privacidad</div>
                </div>
              </Col>
              <Col xs={ 12 } md={ 4 } className='footer-text-desc'>SIGUENOS EN : &nbsp;&nbsp;
                <a href="" target='_blank' style={{ color: '#fff' }}><Instagram className='social-media'></Instagram></a>&nbsp;&nbsp;
                <a href="" target='_blank' style={{ color: '#fff' }}><Facebook className='social-media'></Facebook></a>&nbsp;&nbsp;
                <a href="" target='_blank' style={{ color: '#fff' }}><Tiktok className='social-media'></Tiktok></a>&nbsp;&nbsp;
                <a href="" target='_blank' style={{ color: '#fff' }}><Linkedin className='social-media'></Linkedin></a>
              </Col>
            </Row>
          </Container>
        </div>

      </div>
      
    </div>
  );
}

export default App;
